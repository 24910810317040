<template>
    <div>
      <main>
        <!-- 麵包屑 -->
        <section class="container" data-aos="fade-down"  data-aos-delay="500">
          <nav style="--bs-breadcrumb-divider: '>'" aria-label="breadcrumb" class="badge">
            <ol class="breadcrumb">
              <li class="breadcrumb-item"><router-link to="/">首頁</router-link></li>
              <li class="breadcrumb-item" aria-current="page"><router-link to="unintended-2">我意外懷孕了</router-link></li>
              <li class="breadcrumb-item" aria-current="page"><router-link to="unintended-3-1">我想要生下來</router-link></li>
              <li class="breadcrumb-item" aria-current="page"><router-link to="medical-3-2">醫療議題</router-link></li>
              <li class="breadcrumb-item active" aria-current="page"><router-link to="">意外流產</router-link></li>
            </ol>
          </nav>
        </section>

        <!-- 主要區塊 -->
        <section class="container step-rwd">
          <div class="step">
            <!-- 裝飾用圖片 -->
            <img class="step-circle" src="../../assets/image/front/image_circleword.svg">
            <img class="image1" src="../../assets/image/front/page02_image1.svg">
            <img class="image2" src="../../assets/image/front/page02_image2.svg">
            <img class="image3" src="../../assets/image/front/page02_image3.svg">
            <!-- 結束 -->
            <div class="title-box">
              <p class="step-text" data-aos="fade-up" >ANSEWR</p>
              <h2 class="step-title" data-aos="fade-up">選擇・答案</h2>
              <img class="title-line" src="../../assets/image/front/choose_title_line.svg" data-aos="fade-up" >
              <p class="step-sologan" data-aos="fade-up" >為自己所想，為自己所選。</p>
            </div>
            <div class="container step-box">
              <div class="step-box-inner">
                <div class="box-title" data-aos="fade-up">
                  <h2>｜意外流產｜</h2>
                </div>
                <div class="select-menu">
                  <img class="mb-60" data-aos="fade-up" src='../../assets/image/front/page02_pic3.jpg'>
                  <hr data-aos="fade-up">
                    <!-- 選項開始 -->
                    <!-- 選項開始 -->
                    <ul class="select-item container-inner mt-60">
                      <li data-aos="fade-up">
                        <img class="rounded-0 w-100 pt-2 pb-60" src="https://images.ctfassets.net/i2o3e6knau3c/5edLnOsIWsowXD8CA4JGJN/62823fc4c1e1bc99dd6ee48b842bce58/0205-67.png?w=1728&h=1476&q=100&fm=webp" alt="">
                      </li>
                      <li data-aos="fade-up">
                        <h3>一、流產和早產不同！幾週算流產呢？</h3>
                        <p>懷孕流產其實並不少見，雖然成功懷孕了，卻不是每個人都能順利地生下健康寶寶！在這漫長的孕期當中，有許多未知沒辦法掌控，「流產」就是其中一件事，我們只能盡可能預防、盡可能挽救，但永遠無法保證它不會發生</p>
                      </li>
                      <li data-aos="fade-up">
                        <h3>我出血了！這樣算流產嗎？</h3>
                        <p class="mb-0">其實並不是所有懷孕初期出血都一定是流產，坊間常聽見的小產意思就是「流產」，<br>我們以20週作為分界點：</p>
                        <p class="mt-0 mb-0">1.小於20週就終止妊娠稱為流產（基本上此週數的胎兒無法生存）</p>
                        <p class="mt-0">2.介於20週37週則稱作早產</p>
                        <p class="mb-0">而流產依照方式不同又分為：</p>
                        <p class="mt-0 mb-0">1.自然流產</p>
                        <p class="mt-0">2.人工流產</p>
                      </li>
                      <li data-aos="fade-up">
                        <h3>二、自然流產</h3>
                        <p>世界上有著一群懷了孕卻無法順利生產的媽媽們，暫且不論自願人工流產的人，從懷孕開始，扣除子宮外孕的機率後，還有15%會在懷孕初期發生自然流產，年紀越高機率越高，其中包括自發性的出血流產、早期心跳停止、或空包彈等情形。</p>
                        <p>流產原因是什麼？</p>
                        <p>為什麼別人能順利生下寶寶，我卻流產了呢？是不是我做錯什麼害了寶寶？
這樣的心情、這樣的自責，在自然流產發生時，總是會在產婦身上出現，每當看見這樣的她們，都讓我格外心疼。記住，流產並不是任何人的錯誤，不是因為你做錯什麼、也不是因為你吃什麼會流產，大多數的自然流產都是因為自然淘汰。</p>
                        <p>對完全正常的夫妻而言，約有10-15%的自然流產發生率，
而超過半數的流產起因於胚胎本身的異常，屬於自然淘汰的機轉！</p>
                      </li>
                      <li data-aos="fade-up">
                        <h3>三、人工流產</h3>
                        <p>不可諱言的，終止妊娠是婦產科醫師生涯中最常進行的臨床服務項目之一，無論出於何種緣由選擇接受流產手術或藥物流產，患者自然有她的理由，我從不過問，也無權過問，重要的是對於手術流產和藥物流產的選擇上，醫病雙方必須擁有明確的資訊與適當的諮詢，這也是我提供大家資訊的緣由。</p>
                      </li>
                      <li data-aos="fade-up">
                        <h3>四、小產飲食</h3>
                        <p>小產之後總是擔心自己身體會不會受到影響，該怎麼休養，人工流產後 、自然流產後最多人問的就是關於流產後飲食、流產後補品：小產吃什麼？流產吃什麼？流產補品怎麼吃？等等諸如刺此類的問題不勝枚舉，其實小產後飲食真的有那麼多要注意的地方嗎？我來簡單告訴大家流產飲食注意事項吧！</p>
                      </li>
                      <li data-aos="fade-up">
                        <p>資料來源：<br><a class="fs-5 text-danger" href="https://www.taog.org.tw/ill.php " target="_blank"> 台灣婦產科醫學會</a><br><a class="fs-5 text-danger" href="https://haveababy.tw/posts/pregnancy-20" target="_blank"> Dr.Williams〈擔心自然流產嗎？婦產科醫：流產原因不外乎這六種！〉 </a></p>
                    </li>
                    </ul>
                    <!-- 選項結束 -->
                    <hr data-aos="fade-up">
                    <div data-aos="fade-up">
                      <a class="goBack" onclick="history.back()" value="回到上一頁">回到上一頁</a>
                      <a class="goBack" href="#">返回首頁</a>
                    </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <Consult></Consult>
        <Circle></Circle>
      </main>
    </div>
</template>
<script>
import Consult from '../../components/Consult.vue'
export default {
  data () {
    return {
    }
  },
  mounted () {
  },
  components: {
    Consult
  }
}
</script>
